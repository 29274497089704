import localforage from "localforage";
import Vue from "vue";

const getDefaultState = () => ({
	// Stores duplicates in 2d array
	dupes: null,
	undoStack: [],
	redoStack: []
});

const state = getDefaultState()

const getters = {
	// Get the entire dupes array
	getDupes: state => {
		return state.dupes;
	},
	canUndo: state => {
		return state.undoStack.length > 0;
	},
	canRedo: state => {
		return state.redoStack.length > 0;
	}
}

const mutations = {
	// Reset dupes state
	reset(state) {
		Object.assign(state, getDefaultState());
	},

	// Set all duplicates
	setDupes(state, dupes) {
		// Set dupes
		state.dupes = dupes;
		// Update local storage
		localforage.setItem("dupes", state.dupes);
	},

	// Mark a reference as the one to keep and mark other references as discard
	markAsKeep(state, { listIndex, i, j }) {
		if (state.dupes[listIndex][i]) {
			// Update the keep property
			Vue.set(state.dupes[listIndex][i][j], "keep", true)
			for (const index in state.dupes[listIndex][i]) {
				if (index != j) {
					Vue.set(state.dupes[listIndex][i][index], "keep", false);
				}
			}
		}
		// Update local storage
		localforage.setItem("dupes", state.dupes);
	},

	// Remove a reference from the duplicate array and mark as a non-dupe
	notDupe(state, { listIndex, i, j }) {
		var nonDupe = null;
		// Set to true if only two dupes left and both moved to non-dupes
		// Splice single ref
		nonDupe = state.dupes[listIndex][i].splice(j, 1);
		state.dupes[3].push(nonDupe);
		// Remove list if last element
		if (state.dupes[listIndex][i].length === 1) {
			nonDupe = state.dupes[listIndex].splice(i, 1)[0];
			state.dupes[3].push(nonDupe);
		}
		// Otherwise ensure at least 1 reference is marked to keep
		else if (!state.dupes[listIndex][i].some(dupe => dupe.keep || dupe.isScreened)) {
			Vue.set(state.dupes[listIndex][i][0], "keep", true);
		}
		// Update storage
		localforage.setItem("dupes", state.dupes);
	},
	undoNotDupe(state, { listIndex, i, j, numberMoved }) {
		// If only 1 ref was moved
		if (numberMoved == 1) {
			let refMoveBack = state.dupes[3].pop()[0];
			// If reference to undo is marked as keep, mark all others as discard
			if (refMoveBack.keep) {
				state.dupes[listIndex][i].map(ref => ref.keep = false);
			}
			// Move ref back into list
			state.dupes[listIndex][i].splice(j, 0, refMoveBack);
		}
		// If both refs were moved
		else if (numberMoved == 2) {
			let refsMoveBack = state.dupes[3].splice(-2, 2).flat();
			// Move ref list back into category
			state.dupes[listIndex].splice(i, 0, refsMoveBack);
		} else {
			console.error("Invalid number of refs to undoNotDupe");
		}
		// If there is one previously screened, mark all dupes as discard
		if(state.dupes[listIndex][i].some(dupe => dupe.isScreened)) {
			state.dupes[listIndex][i].map(dupe => dupe.keep = false);
		}
		// Update storage
		localforage.setItem("dupes", state.dupes);
	},

	splitGroup(state, { listIndex, i, splitArray }) {
		var newGroup = [];
		if (state.dupes
			&& state.dupes[listIndex]
			&& state.dupes[listIndex][i]
		) {
			// Add split refs to new group
			splitArray.forEach(j => {
				if (state.dupes[listIndex][i][j]) {
					newGroup.push(state.dupes[listIndex][i][j]);
				}
			})
			// Remove split refs
			Vue.set(state.dupes[listIndex], i, state.dupes[listIndex][i].filter((val, index) => {
				return splitArray.indexOf(index) == -1;
			}));

			// If length of old dupes is 0, remove old group
			if (state.dupes[listIndex][i].length == 0) {
				state.dupes[listIndex].splice(i, 1);
			}
			// Else if length of old dupes is 1, move to non-dupes
			else if (state.dupes[listIndex][i].length == 1) {
				const lastDupe = state.dupes[listIndex].splice(i, 1)[0];
				state.dupes[3].push(lastDupe);
			}
			// Else make sure that at least one reference is marked to be kept
			else {
				// If no references are marked as kept, mark first one
				if (!state.dupes[listIndex][i].some(dupe => dupe.keep || dupe.isScreened)) {
					Vue.set(state.dupes[listIndex][i][0], "keep", true);
				}
			}

			// Push newgroup to non-dupes if only 1 ref
			if (newGroup && newGroup.length == 1) {
				state.dupes[3].push(newGroup);
			}
			// Else create new dupegroup with refs
			else if (newGroup && newGroup.length > 1) {
				// If no references are marked as kept, mark first one
				if (!newGroup.some(dupe => dupe.keep || dupe.isScreened)) {
					newGroup[0].keep = true;
				}
				// Insert at current index
				state.dupes[listIndex].splice(i, 0, newGroup);
			}
			// Update storage
			localforage.setItem("dupes", state.dupes);
		}
	},
	undoSplitGroup(state, { listIndex, i, splitArray, numberRemaining }) {
		// If neither group moved to non-dupes
		if (numberRemaining > 1 && splitArray.length > 1) {
			let splitRefs = state.dupes[listIndex].splice(i, 1).flat();
			// If one to keep in original, remove from split group
			if (state.dupes[listIndex][i].some(ref => ref.keep)) {
				splitRefs.map(ref => ref.keep = false);
			}
			// Insert each ref back in order
			splitArray.forEach((originalIndex, index) => {
				state.dupes[listIndex][i].splice(originalIndex, 0, splitRefs[index]);
			})
		}
		// Else if both refs moved to non-dupes
		else if (numberRemaining == 1 && splitArray.length == 1) {
			let removedRefs = state.dupes[3].splice(-2, 2).flat();
			state.dupes[listIndex].splice(i, 0, removedRefs);
		}
		// Else if only one of the refs moved to non-dupes
		else if (numberRemaining == 1 || splitArray.length == 1) {
			let removedRef = state.dupes[3].pop()[0];
			// If the removed ref is kept, then mark all other refs as discard
			if (removedRef.keep) {
				state.dupes[listIndex][i].map(ref => ref.keep = false);
			}
			// Insert ref back in original location
			if (splitArray.length == 1) {
				state.dupes[listIndex][i].splice(splitArray[0], 0, removedRef);
			} else {
				let isAdded = splitArray.some((originalIndex, index) => {
					if (originalIndex !== index) {
						state.dupes[listIndex][i].splice(index, 0, removedRef);
						return true;
					}
					return false;
				})
				if (!isAdded) {
					state.dupes[listIndex][i].push(removedRef);
				}
			}
		}
		// Else error
		else {
			console.error("Invalid number of refs to undoSplitGroup", numberRemaining, splitArray.length)
		}
		// If there is one previously screened, mark all dupes as discard
		if(state.dupes[listIndex][i].some(dupe => dupe.isScreened)) {
			state.dupes[listIndex][i].map(dupe => dupe.keep = false);
		}
		// Update storage
		localforage.setItem("dupes", state.dupes);
	}
}

const actions = {
	// Undo last action
	undo({ commit, state }) {
		const { commitName, data } = state.undoStack.pop();
		state.redoStack.push({ commitName, data });
		console.log("Undo");
		switch (commitName) {
			case "notDupe":
				commit('undoNotDupe', data);
				break;
			case "splitGroup":
				commit('undoSplitGroup', data);
				break;
			default:
				console.error("Undo: Unknown mutation name")
				break;
		}
	},
	// Redo last action
	redo({ commit, state }) {
		const { commitName, data } = state.redoStack.pop();
		state.undoStack.push({ commitName, data });
		console.log("Redo");
		switch (commitName) {
			case "notDupe":
			case "splitGroup":
				commit(commitName, data);
				break;
			default:
				console.error("Redo: Unknown mutation name")
				break;
		}
	},
	// Remove a reference from the duplicate array and mark as a non-dupe
	notDupe({ commit, state }, { listIndex, i, j }) {
		// Clear redo stack
		state.redoStack = [];
		var numberMoved = 0;
		if (state.dupes
			&& state.dupes[listIndex]
			&& state.dupes[listIndex][i]
			&& state.dupes[listIndex][i][j]
		) {
			numberMoved = state.dupes[listIndex][i].length == 2
				? 2
				: 1;
			commit('notDupe', { listIndex, i, j });
		}
		// Push change to undo stack
		state.undoStack.push({ commitName: "notDupe", data: { listIndex, i, j, numberMoved } })
		return numberMoved;
	},
	// Split Group
	splitGroup({ commit, state }, { listIndex, i, splitArray }) {
		// Clear redo stack
		state.redoStack = [];
		splitArray.sort();
		let numberRemaining = state.dupes[listIndex][i].length - splitArray.length;
		commit('splitGroup', { listIndex, i, splitArray });
		// Push change to undo stack
		state.undoStack.push({ commitName: "splitGroup", data: { listIndex, i, splitArray, numberRemaining } })
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
};