const getDefaultFieldsActive = () => {
	return [
		{
			key: "title",
			name: "Title",
			width: "24%"
		},
		{
			key: "authors",
			name: "Authors",
			width: "17%"
		},
		{
			key: "year",
			name: "Year",
			width: "7%"
		},
		{
			key: "doi",
			name: "DOI",
			width: "17%"
		},
		{
			key: "journal",
			name: "Journal",
			width: "24%"
		},
		{
			key: "volume",
			name: "Volume",
			width: "7%"
		},
		{
			key: "pages",
			name: "Pages",
			width: "7%"
		}
	];
}

const getDefaultFieldsInactive = () => {
	return [
		{
			key: "abstract",
			name: "Abstract",
			width: "7%"
		},
		{
			key: "keywords",
			name: "Article Keywords",
			width: "24%"
		},
		{
			key: "urls",
			name: "URLs",
			width: "24%"
		},
		{
			key: "notes",
			name: "Notes",
			width: "24%"
		},
		{
			key: "researchNotes",
			name: "Research Notes",
			width: "24%"
		},
		{
			key: "date",
			name: "Date",
			width: "7%"
		},
		{
			key: "number",
			name: "Number",
			width: "7%"
		},
		{
			key: "isbn",
			name: "ISSN",
			width: "7%"
		},
		{
			key: "label",
			name: "Label",
			width: "24%"
		},
		{
			key: "caption",
			name: "Caption",
			width: "24%"
		},
		{
			key: "address",
			name: "Address",
			width: "24%"
		}
	];
}

const state = {
	fieldsActive: getDefaultFieldsActive(),
	fieldsInactive: getDefaultFieldsInactive()
}

const getters = {
	getFieldsActive: state => state.fieldsActive,
	getFieldsInactive: state => state.fieldsInactive
}

const mutations = {
	setFieldsActive(state, fields) {
		state.fieldsActive = fields;
	},
	setFieldsInactive(state, fields) {
		state.fieldsInactive = fields;
	},
	reset(state) {
		state.fieldsActive = getDefaultFieldsActive();
		state.fieldsInactive = getDefaultFieldsInactive();
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}