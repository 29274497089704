import Vue from 'vue'
import App from './App.vue'
import store from './store'
import LogRocket from 'logrocket';

Vue.config.productionTip = false

if (process.env.NODE_ENV == 'production')
	LogRocket.init('bond-university-iebh/dedupe');

// Bootstrap Vue
import './theme.scss'
import {
	BNavbar,
	BNavbarBrand,
	BNavbarNav,
	BButton,
	BTabs,
	BTab,
	BFormFile,
	BContainer,
	BModal,
	BCard,
	BCardHeader,
	BCardBody,
	BCollapse,
	VBToggle,
	BIconCaretRight,
	BIconCaretDown,
	BButtonToolbar,
	BButtonGroup,
	BDropdown,
	BDropdownItem,
	BDropdownDivider,
	BDropdownGroup,
	VBModal,
	BRow,
	BCol,
	BProgress,
	BProgressBar,
	BFormTextarea,
	BIconX,
	BFormGroup,
	BFormInput,
	BFormTags,
	BFormSelect,
	BFormCheckbox,
	BIconQuestionCircleFill,
	BIconPlus,
	BTooltip,
	BInputGroup
} from '@iebh/bootstrap-vue';
// Navbar
Vue.component('b-navbar', BNavbar);
Vue.component('b-navbar-brand', BNavbarBrand);
Vue.component('b-navbar-nav', BNavbarNav);
// Button
Vue.component('b-button', BButton);
// File Upload
Vue.component('b-tabs', BTabs);
Vue.component('b-tab', BTab);
Vue.component('b-form-file', BFormFile);
Vue.component('b-container', BContainer);
// Warning modal
Vue.component('b-modal', BModal);
// Dupes
Vue.component('b-card', BCard);
Vue.component('b-card-header', BCardHeader);
Vue.component('b-card-body', BCardBody);
Vue.component('b-collapse', BCollapse);
Vue.directive('b-toggle', VBToggle );
Vue.component('BIconCaretRight', BIconCaretRight);
Vue.component('BIconCaretDown', BIconCaretDown);
Vue.component('BFormCheckbox', BFormCheckbox);
// Toolbar
Vue.component('b-button-toolbar', BButtonToolbar);
Vue.component('b-button-group', BButtonGroup);
Vue.component('b-dropdown', BDropdown);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('b-dropdown-group', BDropdownGroup);
Vue.directive('b-modal', VBModal );
Vue.component('b-row', BRow );
Vue.component('b-col', BCol );
// Progress
Vue.component('b-progress', BProgress );
Vue.component('b-progress-bar', BProgressBar );
// Edit Reference
Vue.component('b-form-textarea', BFormTextarea );
Vue.component('b-icon-x', BIconX );
Vue.component('b-form-group', BFormGroup );
Vue.component('b-form-input', BFormInput );
Vue.component('b-form-tags', BFormTags );
// Export
Vue.component('b-form-select', BFormSelect);
// Help Hover
Vue.component('b-icon-question-circle-fill', BIconQuestionCircleFill);
Vue.component('b-icon-plus', BIconPlus);
Vue.component('b-tooltip', BTooltip);
Vue.component('b-input-group', BInputGroup);
// Toast
import { ToastPlugin } from '@iebh/bootstrap-vue';
Vue.use(ToastPlugin);

new Vue({
	render: h => h(App),
	store: store
}).$mount('#app')
