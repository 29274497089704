<template>
	<div class="m-4">
		<div class="accordion" role="tablist" style="height: 300px">
			<!-- DUPE CATEGORIES -->
			<div v-for="category of categoryList" :key="category.index">
			<b-card bg-variant="secondary-light" no-body class="mb-1">
				<b-card-header header-tag="header" class="p-0" role="tab">
					<b-button class="no-border text-left" block v-b-toggle="`accordion-${category.index}`" variant="secondary">
						<span class="when-closed"><BIconCaretRight /></span>
						<span class="when-opened"><BIconCaretDown /></span>
						{{category.name}} ({{dupeLengths[category.index]}}) - [{{category.check}}]
					</b-button>
				</b-card-header>
				<b-collapse class="dupe-area" :id="`accordion-${category.index}`" accordion="category-accordion" role="tabpanel">
					<div class="m-3">
						<DupesAreaPagination
							v-model="currentPages[category.index]"
							@input="scrollToTop(`accordion-${category.index}`)"
							:total-rows="dupeCategories[category.index].length"
							:per-page="perPage"
							:startIndex="startIndex[category.index]"
							:endIndex="endIndex[category.index]"
						/>
					</div>
					<div
						v-for="(dupeList, i) of dupeCategories[category.index].slice(startIndex[category.index], endIndex[category.index])"
						:key="i"
						class="m-3"
					>
						<DupesAreaTable
							:group="category.index"
							:absoluteIndex="i"
							:index="startIndex[category.index] + i"
							:dupeList="dupeList"
							@not-dupe="removeDupe(category.index, startIndex[category.index] + i, $event)"
							@split-group="splitGroup(category.index, startIndex[category.index] + i, $event)"
						/>
					</div>
					<div class="m-3">
						<DupesAreaPagination
							v-model="currentPages[category.index]"
							:total-rows="dupeCategories[category.index].length"
							:per-page="perPage"
							:startIndex="startIndex[category.index]"
							:endIndex="endIndex[category.index]"
						/>
					</div>
					<div class="m-3">
					</div>
				</b-collapse>
			</b-card>
			</div>

			<!-- NON DUPES -->
			<b-card bg-variant="secondary-light" no-body class="mb-1">
				<b-card-header header-tag="header" class="p-0" role="tab">
					<b-button
						class="no-border text-left"
						variant="secondary"
						block v-b-toggle.accordion-3
						@click="nonDupesDisplay = !nonDupesDisplay"
					>
						<span class="when-closed"><BIconCaretRight /></span>
						<span class="when-opened"><BIconCaretDown /></span>
						Non-Duplicates ({{dupeLengths[3]}}) - [Don't Check]
					</b-button>
				</b-card-header>
				<b-collapse class="dupe-area" id="accordion-3" accordion="category-accordion" role="tabpanel">
					<div class="m-3">
						<DupesAreaVirtualTable
							:group="3"
							:dupeList="dupeCategories[3].flat()"
							:display="nonDupesDisplay"
						/>
					</div>
				</b-collapse>
			</b-card>

			<!-- UNDO TOAST -->
			<b-toast
				id="undo-toast"
				title="Action"
				variant="info"
				solid
				:no-fade="true"
				toaster="b-toaster-top-center"
			>
				<span class="mr-3">{{this.message}}</span>
				<b-button variant="info" @click="undoToast" size="sm">Undo (Ctrl+Z)</b-button>
			</b-toast>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import formatMixin from '../mixins/formatMixin'

import DupesAreaTable from './DupesAreaTable.vue'
import DupesAreaVirtualTable from './DupesAreaVirtualTable.vue';
import DupesAreaPagination from './DupesAreaPagination.vue';

export default {
	name: "DupesArea",
	mixins: [
		formatMixin
	],
	components: {
		DupesAreaTable,
		DupesAreaVirtualTable,
		DupesAreaPagination
	},
	data() {
		return {
			currentPages: [1, 1, 1],
			perPage: 100,
			message: "",
			nonDupesDisplay: false,
			categoryList: [
				{
					name: "Extremely Likely Duplicates",
					check: "Super Quick Check",
					index: 0
				},
				{
					name: "Highly Likely Duplicates",
					check: "Quick Check",
					index: 1
				},
				{
					name: "Likely Duplicates",
					check: "Thorough Check",
					index: 2
				}
			]
		}
	},
	computed: {
		...mapGetters({
			dupeCategories: 'duplicates/getDupes'
		}),
		startIndex() {
			return this.currentPages.map(page => (page - 1) * this.perPage);
		},
		endIndex() {
			return this.currentPages.map(page => page * this.perPage);
		},
		dupeLengths() {
			return [
				this.dupeCategories[0].flat().length,
				this.dupeCategories[1].flat().length,
				this.dupeCategories[2].flat().length,
				this.dupeCategories[3].flat().length,
			];
		}
	},
	methods: {
		async removeDupe(listIndex, i, j) {
			const numMoved = await this.$store.dispatch('duplicates/notDupe', { listIndex, i, j });
			this.$bvToast.hide('undo-toast');
			this.message = numMoved > 1 ? `${numMoved} references moved to non-dupes` : `${numMoved} reference moved to non-dupes`;
			setTimeout(() => {
				this.$bvToast.show('undo-toast');
			}, 50)
		},
		splitGroup(listIndex, i, splitArray) {
			this.$store.dispatch('duplicates/splitGroup', { listIndex, i, splitArray });
			this.$bvToast.hide('undo-toast');
			this.message = "Duplicate group split";
			setTimeout(() => {
				this.$bvToast.show('undo-toast');
			}, 200)
		},
		undoToast() {
			this.$store.dispatch('duplicates/undo');
			this.$bvToast.hide('undo-toast');
		},
		scrollToTop(id) {
			document.getElementById(id).scrollTo(0, 0)
		}
	}
}
</script>

<style lang="scss">
@import "@/theme.scss";
.no-border:focus, .no-border:active {
	outline: none !important;
	box-shadow: none !important;
}
.dupe-area {
	max-height: calc(100vh - 290px) !important;
	overflow: auto;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
		display: none;
}
</style>